/* SF Pro Display Font Faces */
@font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display/SF-Pro-Display-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('./assets/fonts/SF-Pro-Display/SF-Pro-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('/src/assets/fonts/SF-Pro-Display/SF-Pro-Display-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }